import React from 'react'

type Props = {}

const ProjectsSection = ({ handleOnButtonClick,sectionRef }: any) => {
    return (
      <>
        <div ref={sectionRef} id="projects" className="ml-0.5 mt-1">
        <div  className="text-2xl mb-2">Projects</div>
  
          <div>Coming Soon</div>
          {/* <p>
            I have obtained skills in both Software and Networks over a four year
            period. I have a significant knowledge in Java programming
            understanding, As well as having completed projects in Android,
            HTML/CSS Assembly code, javaScript and python during my degree and my
            experience in the workplace.
          </p> */}
          {/* <div>Project 1</div>
          <p>
            I have obtained skills in both Software and Networks over a four year
            period. I have a significant knowledge in Java programming
            understanding, As well as having completed projects in Android,
            HTML/CSS Assembly code, javaScript and python during my degree and my
            experience in the workplace.
          </p> */}
        </div>
      </>
    );
  };

export default ProjectsSection