import { useState } from "react";
import "./App.css";
import { useInView } from "react-intersection-observer";

import LinksSections from "./components/LinksSections";
import AboutMeSection from "./components/AboutMeSection";
import ProjectsSection from "./components/ProjectsSection";
import ContactMeSection from "./components/ContactMeSection";
import ExperienceSection from "./components/ExperienceSection";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { ref: refContactMe, inView: contactMeVisible } = useInView();
  const { ref: refProjects, inView: projectsVisible } = useInView();
  const { ref: refAboutMe, inView: aboutMeVisible } = useInView();
  const { ref: refExperience, inView: experienceVisible } = useInView();

  const headerItems: { label: string; id: string; inView: boolean }[] = [
    { label: "About me", id: "about", inView: aboutMeVisible },
    { label: "Experience", id: "experience", inView: experienceVisible },

    { label: "Projects", id: "projects", inView: projectsVisible },
    { label: "Contact me", id: "contactme", inView: contactMeVisible },
  ];

  return (
    <div
      className={`scroll-smooth ${
        isDarkMode ? `bg-blue-950 text-white` : `text-gray-500 bg-white`
      }`}
    >
      <div className="h-screen flex  ">
        <div className="w-1/2 h-2/3 ml-8">
          <nav className=" pt-4  flex items-end justify-start  items-contents-start ">
            <button
              className={`border-2 rounded-md px-3 py-2 font-semibold ${
                isDarkMode
                  ? `hover:bg-[#FFD717] hover:text-black`
                  : `hover:bg-yellow-300 hover:text-white`
              }`}
              onClick={() => {
                setIsDarkMode((prev) => !prev);
              }}
            >
              {isDarkMode ? "Light mode" : "Dark mode"}
            </button>
          </nav>
          <h1 className="font-bold text-5xl mt-4 ">Patrick Munnelly</h1>
          <h1 className="font-semibold text-3xl">Software Engineer </h1>
          <h1 className="w-2/3">
            Developer that excels in the frontend but works well on the backend
            and know to run the QA for a company.
          </h1>

          <div className=" pt-4 mb-4">
            {headerItems.map((item) => {
              return (
                <div
                  className={`ml-4 ${
                    item.inView ? `font-black` : ``
                  } hover:font-black flex flex-row`}
                >
                  <a href={`#${item.id}`}>{item.label}</a>
                </div>
              );
            })}
          </div>
          <LinksSections
          //  sectionRef={refLinks}
          />
        </div>
        <div className="flex-1 flex overflow-hidden mt-16 ">
          <div className="pr-8 flex-1 overflow-y-scroll">
            <AboutMeSection sectionRef={refAboutMe} />
            <div id="blank" className="h-screen">
              {" "}
            </div>
            <div className="h-screen">
              <ExperienceSection sectionRef={refExperience} />
            </div>
            <div id="blank2" className="h-screen">
              {" "}
            </div>
            <div className="h-screen">
              <ProjectsSection sectionRef={refProjects} />
            </div>

            <div className="h-screen">
              <ContactMeSection sectionRef={refContactMe} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
