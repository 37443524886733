import React from 'react'

type Props = {}

export default function LinksSections({}: Props) {
    return (
        <div className="mr-32 ml-8 grid grid-cols-3">
          <div className="my-0.5">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/Patrick-Munnelly"
            >
              Github
            </a>
          </div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/pmunnelly/"
            >
              LinkedIn
            </a>
          </div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/pmunnelly/"
            >
              Twitter
            </a>
          </div>
        </div>
      );
}