import React from 'react'

type Props = {}


const ContactMeSection = ({ handleOnButtonClick,sectionRef }: any) => {
    return (
      <div ref={sectionRef}  id="contactme" className="ml-0.5 mt-1 mb-8">
        <div className="text-2xl mb-2">Contact me</div>
        <div className="my-0.5">Contact me at</div>
        <div className="">Patrick.m.munnelly@gmail.com</div>
      </div>
    );
  };
  

  export default ContactMeSection