import React from 'react'

// type Props = {}

const AboutMeSection = ({ sectionRef }: any) => {
    return (
      <div ref={sectionRef} id="about" className="ml-0.5 mt-1 mt-16">
        <div  className="text-2xl mb-2">About me</div>
        <span>I am currently a Software Engineer.</span>
        <p>
          I am an experienced software engineer with a proven track record of
          delivering innovative and scalable solutions across diverse sectors such
          as financial and construction sectors. My expertise extends across the
          full software development life cycle, I have experience in backend and
          frontend development but my expertise is in Frontend development and
          quality assurance testing.
        </p>
        <p>
          I am now seeking a new position where I can leverage my expertise to
          drive technological excellence, foster innovation, and contribute to the
          success of a new company (hopefully yours).
        </p>
      </div>
    );
  };

export default AboutMeSection