import React from 'react'

type Props = {}


const ExperienceSection = ({ handleOnButtonClick, sectionRef }: any) => {
    return (
      <>
        <div ref={sectionRef} id="experience" className="ml-0.5 mt-4">
          <div className="text-2xl mb-2">Experience</div>
  
          <div>Software Engineer</div>
          <div>Trilateral Research</div>
          <div>2021 - Present</div>
          <p>
            As a seasoned software engineer deeply engaged in the development of
            CESIUM, an award-winning Ethical AI platform dedicated to safeguarding
            children and combating child exploitation, I bring an of expertise in
            full-stack development to this impactful initiative. From utilising
            Python for robust Backend solutions, to employing front-end frameworks
            and Tools. Such as NextJS , Tailwind, and npm to craft dynamic and
            responsive user interfaces. The prestigious DataIQ award, attests to
            the success of our co-design relationship with Lincolnshire Police and
            the positive impact of my contributions to this groundbreaking
            initiative.
          </p>
       
        <div className="ml-0.5 mt-4">
          <div>Software Engineer</div>
          <div>Boyne Park Fitout</div>
          <div> 2018 - 2021</div>
          <p>
            In my role as a dedicated software engineer within a construction
            company, I have successfully driven Custom Solution Development aimed
            at enhancing operational efficiency and productivity in the company.
            My primary responsibilities included designing and implementing
            tailored solutions for the tracking of employee work, qualifications,
            site progress, and material requirements. I spearheaded the
            development of two crucial applications, a Flutter-based mobile app
            and a ReactJS web app. Both sharing a unified backend infrastructure.
            Furthermore, my role extends beyond development to encompass the
            entire software lifecycle, as I actively participate in the design,
            deployment, and ongoing maintenance of the web and mobile
            applications, always prioritising maintainability and delivering
            user-friendly experiences tailored to the unique needs of the
            construction industry.
          </p>
        </div>
  
        <div className="ml-0.5 mt-4">
          <div>Associate Systems Developer</div>
          <div>Pramamerica</div>
          <div> 2016 -2018</div>
          <p>
            As a software engineer within a multinational insurance company, my
            focus was on developing an Insurance Web Applications. These
            applications where build in html, javascript and CSS and the other
            application was built with React and bootstrap.
          </p>
        </div>
        <div className="ml-0.5 mt-4">
          <div>Software Developer Intern</div>
          <div>Pramamerica</div>
          <div> 2015 -2016</div>
          <p>
            At Pramerica as an Intern I was responsible for testing and developing
            an understanding for the system and processes used by the team. Once I
            gained that knowledge I was responsible for developing and maintaining
            the company's online Life Insurance products. This includes developing
            on the front-end and back-end of the products.
          </p>
        </div>
        </div>
      </>
    );
  };

export default ExperienceSection